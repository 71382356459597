@import "../widgets/chat-widget/chat-widget-global-styles.css";

:root {
	--callout-rgb: 238, 240, 241;
	--callout-border-rgb: 172, 175, 176;
	--card-rgb: 180, 185, 188;
	--card-border-rgb: 131, 134, 135;

    --blue-0: $blue-0;
    --blue-1: $blue-1;
    --blue-2: $blue-2;
    --blue-3: $blue-3;
    --blue-4: $blue-4;
    --blue-5: $blue-5;
    --blue-6: $blue-6;
    --blue-7: $blue-7;
    --blue-8: $blue-8;
    --blue-9: $blue-9;

    --red-0: $red-0;
    --red-1: $red-1;
    --red-2: $red-2;
    --red-3: $red-3;
    --red-4: $red-4;
    --red-5: $red-5;
    --red-6: $red-6;
    --red-7: $red-7;
    --red-8: $red-8;
    --red-9: $red-9;

    --grey-0: $grey-0;
    --grey-1: $grey-1;
    --grey-2: $grey-2;
    --grey-3: $grey-3;
    --grey-4: $grey-4;
    --grey-5: $grey-5;
    --grey-6: $grey-6;
    --grey-7: $grey-7;
    --grey-8: $grey-8;
    --grey-9: $grey-9;

    --green-0: $green-0;
    --green-1: $green-1;
    --green-2: $green-2;
    --green-3: $green-3;
    --green-4: $green-4;
    --green-5: $green-5;
    --green-6: $green-6;
    --green-7: $green-7;
    --green-8: $green-8;
    --green-9: $green-9;

    --yellow-0: $yellow-0;
    --yellow-1: $yellow-1;
    --yellow-2: $yellow-2;
    --yellow-3: $yellow-3;
    --yellow-4: $yellow-4;
    --yellow-5: $yellow-5;
    --yellow-6: $yellow-6;
    --yellow-7: $yellow-7;
    --yellow-8: $yellow-8;
    --yellow-9: $yellow-9;
}

* {
	font-family: var(--font-montserrat), sans-serif;
	font-style: normal;
	font-weight: 400;
}
*[data-if='false'] {
	display: none !important;
}


body{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.overflow-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.red-dot {
	position: absolute;
	right: -20px;
	top: 7px;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	background: red;
}

:root {

}

.container {
	width: 100%;
	max-width: $container-xs;
	margin: 0 auto;

	/* Breakpoints */
	@media (min-width: $mantine-breakpoint-xs) {
		max-width: $container-sm;
	}
	@media (min-width: $mantine-breakpoint-sm) {
		max-width: $container-md;
	}
	@media (min-width: $mantine-breakpoint-md) {
		max-width: $container-lg;
	}
	@media (min-width: $mantine-breakpoint-lg) {
		max-width: $container-xl;
	}
	@media (min-width: $mantine-breakpoint-xl) {
		max-width: $container-xxl;
	}
}

.visually-hidden {
	/*
        Class for visually hiding elements while keeping them accessible
        to screen readers and search engines. Useful for SEO and
        assistive technologies.
    */
	position: absolute;
	width: 1px; /* Ensures the element takes minimal space */
	height: 1px; /* Ensures the element takes minimal space */
	padding: 0;
	margin: 0;
	overflow: hidden; /* Hides overflowing content */
	clip: rect(0, 0, 0, 0); /* Hides content visually (legacy method) */
	white-space: nowrap; /* Prevents wrapping for better accessibility */
	border: 0;
}

