.root {
    box-shadow: 0 0 4px 0 rgba(7, 59, 102, 0.04), 0 0 20px 0 rgba(7, 59, 102, 0.06);
    background-color: #FFFFFF;
    color: #0A2432;
    padding: 40px;
    display: flex;
    gap: 16px;
    flex-direction: column;

    justify-content: space-between;
    left: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 999;

    @mixin larger-than $mantine-breakpoint-xs {
        gap: 40px;
        flex-direction: row;
    }


    .contentContainer {
        margin: 0;

        p {
            color: #0A2432;
            margin: 0;
            padding: 0;
        }

        .title {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px; /* 140% */
            margin-bottom: 8px;
        }

        .description {
            font-size: 16px;
            font-weight: 400;
            line-height: 28px; /* 175% */
        }

        .privacyLink {
            text-decoration: none;
            color: #0061AF;
            font-weight: 500;
        }
    }
}