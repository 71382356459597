.chat-widget__button--expand {
    display: none !important;
}

.chat-widget__button--close {
    background-color: transparent !important;
    box-shadow: unset !important;
    margin: 0 !important;
    position: fixed !important;
    top: 12px !important;
    right: 16px !important;
    display: block !important;
    width: 40px !important;
    height: 40px !important;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Imljb24vY2xvc2UiPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNNyAyMS4wMDAyTDIxIDcuMTQwODciIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggaWQ9IlZlY3Rvcl8yIiBkPSJNNyA3TDIxIDIwLjg1OTMiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8L3N2Zz4K');
    background-repeat: no-repeat !important;
    background-position: center !important;

    i {
        display: none !important;
    }
}

.chat-widget__content {
    box-shadow: 0 2px 16px -4px rgba(10, 36, 50, 0.08), 0 4px 6px -2px rgba(10, 36, 50, 0.03);
    width: 520px !important;
}

@media screen and (max-width: 520px) {
    .chat-widget__content {
        max-width: 100% !important;
        width: 100% !important;
    }
}